import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { SEO, BasicPageTopper, Offerings, CTA } from 'components';
import styles from './whatWeOffer.module.scss';

const WhatWeOffer = ({ data, location: { pathname } }) => {
  const {
    page: { headline, intro, image, blocks, seo, cta, hideFooterCta },
  } = data;

  return (
    <Fragment>
      <SEO {...seo} pathname={pathname} />
      <Helmet
        bodyAttributes={{
          class: 'has-animation',
        }}
      />
      <BasicPageTopper
        title={headline}
        intro={intro}
        image={image}
        className={styles.lightCaption}
      />
      <Offerings offerings={blocks} />
      {!hideFooterCta && <CTA {...cta} showWhiteArea={false} onFooter />}
    </Fragment>
  );
};

WhatWeOffer.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query WhatWeOfferPage($slug: String!) {
    page: contentfulWhatWeOffer(slug: { eq: $slug }) {
      title
      headline
      intro {
        intro
      }
      image {
        caption {
          json
        }
        media {
          title
          description
          file {
            url
          }
        }
      }
      blocks {
        ... on ContentfulCardLink {
          url
          title
          tease
        }
        ... on ContentfulPage {
          slug
          title
          tease
        }
      }
      cta {
        ...CTA
      }
      hideFooterCta
      seo {
        ...SEO
      }
    }
  }
`;

export default WhatWeOffer;
